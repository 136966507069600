import { useParams } from 'react-router-dom';
import { useDocument } from '../../hooks/useDocument';
import DetailedTicket from '../../components/DetailedTicketView/DetailedTicket';
import TicketVisits from '../../components/TicketVisits/TicketVisits';
import TicketNotes from '../../components/TicketNotes/TicketNotes';
import Grid from '@mui/material/Grid';

const TicketDetailsPage = () => {
  const { ticketId } = useParams();

  const { document: ticket } = useDocument('tickets', ticketId);

  return <>
  {ticket && 
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <DetailedTicket ticket={ticket} />
      </Grid>
      <Grid item xs={6}>
        <TicketVisits ticketId={ticketId} ticketState={ticket.status.state[0]} />
      </Grid>
      <Grid item xs={6}>
        <TicketNotes ticket={ticket} />
      </Grid>
    </Grid>
  }
  </>
}

export default TicketDetailsPage;