import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { useCollection } from '../../hooks/useCollection';
import VisitCard from './VisitCard';

const TicketVisits = ({ ticketId, ticketState }) => {
  const { documents: visits } = useCollection('ticket_visit', ['ticket.id', '==', ticketId]);
  
  return <>
    <Typography variant='overline'>Technician Visits</Typography>
    { visits && visits.length === 0 && (<Paper
        sx={{
          p: 2,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Typography variant='subtitle2' textAlign='center'>No Visits Currently Scheduled</Typography>
      </Paper>
    )}

    {visits && visits.length > 0 && (
      visits.map(visit => (<VisitCard visit={visit} ticketState={ticketState} />))
    )}
  </>
};

export default TicketVisits;