import { useState } from "react";
import { Button } from '@mui/material'

const UploadAttachmentsForm = (props) => {
  const {
    formField: { attachments },
    setFieldValue
  } = props;

  const [selectedFiles, setSelectedFiles] = useState(null);

  const handleSelectFiles = (event) => {
    setSelectedFiles(event.currentTarget.files)
    setFieldValue("attachments", event.currentTarget.files);
  }

  const numberOfAttachments = selectedFiles && selectedFiles.length
  const attachmentArray = []
  for (let ind = 0; ind < numberOfAttachments; ind++) {
    attachmentArray[ind] = selectedFiles[ind]
  }


  return <div>
    <div style={{ marginTop: '1rem' }}>{attachments.label}</div>
    <div style={{ minHeight: '8rem', width: '75%', backgroundColor: '#dddddd', marginBottom: '1rem' }}>
    {attachmentArray.length > 0 && (
      attachmentArray.map((attach) => {
        return <div>
          {attach.name}
        </div>
      })
    )}
    </div>
    
    {/* <input 
      type="file" 
      name={attachments.name}
      multiple
      onChange={handleSelectFiles}
    /> */}
    <Button variant="contained" component="label">
      Upload
      <input
        type="file" 
        name={attachments.name}
        multiple
        onChange={handleSelectFiles} 
        hidden />
    </Button>
  </div>
}

export default UploadAttachmentsForm;