import { useState } from 'react';
import { Grid, Typography, IconButton, Dialog, Tooltip } from '@mui/material';
import AddLocationAltIcon from '@mui/icons-material/AddLocationAlt';
import { SelectField } from '../../FormFields/index';
import NewLocationForm from './NewLocationForm';
import { useCollection } from '../../../hooks/useCollection';
import { useAuthContext } from '../../../hooks/useAuthContext';

const LocationForm = (props) => {
  const {
    formField: {
      location,
    }
  } = props;

  const [isNewLocModalOpen, setIsNewLocModalOpen] = useState(false);

  const { user } = useAuthContext();
  const { documents: locations } = useCollection('locations', ['client.id', '==', user.company.id]);

  const handleModalOpen = () => {
    setIsNewLocModalOpen(true);
  };

  const handleModalClose = () => {
    setIsNewLocModalOpen(false);
  };

  return (
    <>
      <Typography variant="h6" gutterBottom>
        Service Location
      </Typography>

      {locations && <Grid container justifyContent="flex-start">
        <Grid item xs={12} sm={8}>
          <SelectField
            name={location.name}
            label={location.label}
            data={locations}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={1}>
          <Tooltip title="Add New Location" placement="bottom-start">
            <IconButton onClick={handleModalOpen} size="large">
              <AddLocationAltIcon color="primary" />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>}

      <Dialog open={isNewLocModalOpen} onClose={handleModalClose} maxWidth='sm' fullWidth={true}>
        <NewLocationForm closeModal={handleModalClose} />
      </Dialog>
    </>
  );
};

export default LocationForm;