const ticketTypes = [
  { name: 'Service', id: 1 },
  { name: 'Estimate', id: 0 },
];

const ticketPriority = [
  { name: 'Emergency - Within 4 hours', shortName: 'Emergency', id: '0', color: "#FA1E1E"},
  { name: 'Next Day - Within 24 hours', shortName: 'Next Day', id: '1', color: "#EC9219" },
  { name: 'Normal - Within 3 days', shortName: 'Normal', id: '2' , color: "#26A20B"},
  { name: 'Routine - Within 7 days', shortName: 'Routine', id: '3', color: "#3A69D5" },
  { name: 'Preventative - Scheduled', shortName: 'Preventative', id: '4', color:  "#8a8a8a"},
];

const ticketCategory = [
  { name: 'Plumbing', id: 1 },
  { name: 'Concrete', id: 2 },
  { name: 'Roofing', id: 3 },
  { name: 'Doors', id: 4 },
  { name: 'Window Coverings', id: 5 },
  { name: 'Demolition', id: 6 },
  { name: 'Paint And Wall Coverings', id: 7 },
  { name: 'Millwork', id: 8 },
  { name: 'Glass And Glazing', id: 9 },
  { name: 'Electrical', id: 10 },
  { name: 'Fire Sprinklers', id: 11 },
  { name: 'Carpentry', id: 12 },
  { name: 'Flooring', id: 13 },
  { name: 'HVAC', id: 14 },
  { name: 'Miscellaneous', id: 15 },
  { name: 'Lighting', id: 16 },
  { name: 'Landscaping', id: 17 },
];

const ticketStatus = [
  { name: 'Pending', id: 0 },
  { name: 'Open', id: 1 },
  { name: 'Completed', id: 2 },
  { name: 'Closed', id: 3 },
];

const ticketState = [
  { name: 'Scheduled', id: 0 },
  { name: 'Dispatched', id: 1 },
  { name: 'Unscheduled', id: 2 },
  { name: 'On Site', id: 3 },
  { name: 'Awaiting Invoice', id: 4 },
  { name: 'Overdue', id: 5 },
  { name: 'Unassigned', id: 6 },
  { name: 'Assigned', id: 7 },
  { name: 'Awaiting Estimate', id: 8 },
  { name: 'Payment Required', id: 9 },
];

const visitStatus = [
  { name: 'Assigned', id: 0 },
  { name: 'Accepted', id: 1 },
  { name: 'Dispatched', id: 2 },
  { name: 'On Site', id: 3 },
  { name: 'Completed', id: 5 },
  { name: 'Rejected', id: 6 },
  { name: 'Cancelled', id: 7 },
];

const getTicketType = (id) => {
  return ticketTypes.find(type => type.id === id);
}

const getPriority = (id) => {
  return ticketPriority.find(priority => priority.id === id);
}

const getCategory = (ids) => {
  const categories = [];
  for (let i = 0; i < ids.length; i++) {
    let cat = ticketCategory.find(category => category.id === ids[i]);
    categories.push(cat);
  }
  return categories;
};

const getVisitStatus = (id) => {
  return visitStatus.find(status => status.id === id)
}

export {
  ticketTypes,
  ticketPriority,
  ticketCategory,
  ticketStatus,
  ticketState,
  visitStatus,
  getTicketType,
  getPriority,
  getCategory,
  getVisitStatus,
};
