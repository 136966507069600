import { ref, uploadBytes, uploadBytesResumable } from 'firebase/storage'
import { storage } from '../firebase/config'

const ticketAttachmentsPath = 'ticket/attachment/'
export const useStorage = () => {
  const uploadTicketAttachments = (company, location, ticket, file, user) => {
    const attachmentPath = ticketAttachmentsPath + company + '/' + location + '/' + ticket + '/' + file.name
    const attachmentsRef = ref(storage, attachmentPath);

    let generic_type = 'file'
    if (file.type === 'image/jpeg') {
      generic_type = 'image'
    }

    const metadata = {
      customMetadata: {
        'uploaded_by_id': user,
        'generic_type': generic_type
      }
    }
    
    uploadBytes(attachmentsRef, file, metadata).then((snapshot) => {
      console.log(attachmentPath)
      console.log('Uploaded a blob or file!');
    }).catch(err => console.log(err));

  }

  return {
    uploadTicketAttachments
  }

}