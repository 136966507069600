import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { useCollection } from '../../hooks/useCollection';

const TicketNotes = ({ ticket }) => {
  const { documents: notes } = useCollection('ticket_notes', ['ticket.id', '==', ticket]);

  return <>
    <Typography variant='overline'>Notes</Typography>
      <Paper
        sx={{
          p: 2,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
      {notes && notes.length === 0 && 
        <Typography variant='subtitle2' textAlign='center'>No Notes Currently</Typography>
      }
      {notes && notes.length > 0 && 
        <div>Figuring it out</div>
      }
      </Paper>

  </>
}

export default TicketNotes;