import { useReducer, useEffect, useState } from 'react';
import { functions } from '../firebase/config';
import { httpsCallable } from 'firebase/functions';

const initialState = {
  document: null,
  isPending: false,
  error: null,
  success: null,
}

const firestoreReducer = (state, action) => {
  switch(action.type){
    case 'IS_PENDING':
      return { isPending: true, document: null, success: null, error: null }

    case 'ADDED_DOCUMENT':
      return { isPending: false, document: action.payload, success: true, error: null }

    case 'ERROR':
      return { isPending: false, document: null, success: false, error: action.payload }

    default:
      return state;
  }
}

export const useFirestore = (callable) => {
  const [response, dispatch] = useReducer(firestoreReducer, initialState);
  const [isCancelled, setIsCancelled] = useState(false);

  // only dispatch if not cancelled
  const dispatchIfNotCancelled = (action) => {
    if (!isCancelled){
      dispatch(action);
    }
  }

  const addDocument = async (data) => {
    dispatch({ type: 'IS_PENDING' })

    try {
      const createDocument = httpsCallable(functions, callable);
      const addedDocument = await createDocument(data);
      
      dispatchIfNotCancelled({ type: 'ADDED_DOCUMENT', payload: addedDocument })


    } catch(err) {
      console.log(err.message);
      dispatchIfNotCancelled({ type: 'ERROR', payload: {
        code: err.code,
        message: err.message,
        details: err.details,
      }});
    }
  }

  useEffect(() => {
    setIsCancelled(false);
    return () => setIsCancelled(true);
  }, []);

  return {
    addDocument,
    response
  }
  
}