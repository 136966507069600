import { useState } from 'react';
import { Navigate } from 'react-router-dom';
import {
  Paper,
  Stepper,
  Step,
  StepLabel,
  Button,
  Typography,
  CircularProgress
} from '@mui/material';
import { Formik, Form } from 'formik';

import { useFunctions } from '../../hooks/useFunctions';
import { useFirestore } from '../../hooks/useFirestore';
import { useAuthContext } from '../../hooks/useAuthContext';
import { useStorage } from '../../hooks/useStorage';

import { 
  LocationForm,
  TicketDetailsForm,
  ReviewTicket,
  UploadAttachmentsForm
} from '../../components/TicketForms';

import ticketFormModel from './FormModels/TicketFormModel';
import formInitialValues from './FormModels/formInitialValues';
import validationSchema from './FormModels/ValidationSchema';

import './NewTicket.scss';

const steps = ['Location', 'Details', 'Upload Attachments', 'Review Ticket'];
const { formId, formField } = ticketFormModel;

function renderStepContent(step, setFieldValue) {
  switch (step) {
    case 0:
      return <LocationForm formField={formField} />;
    case 1:
      return <TicketDetailsForm formField={formField} />;
    case 2:
      return <UploadAttachmentsForm formField={formField} setFieldValue={setFieldValue} />
    case 3:
      return <ReviewTicket />;
    default:
      return <div>Not Found</div>;
  }
}

const NewTicketPage = () => {
  const [activeStep, setActiveStep] = useState(0);
  const currentValidationSchema = validationSchema[activeStep];
  const isLastStep = activeStep === steps.length - 1;

  const { getTicketId } = useFunctions();
  const { addDocument } = useFirestore('ticketFunctions-createNewTicket');
  const { uploadTicketAttachments } = useStorage();
  const { user} = useAuthContext();

  async function _submitForm(values, actions) {
    const ticketId = getTicketId();
    // const companyId = '3Cb53dTYpNr4Tg6dKnmt';
    const companyId = 'W1bvChpYk8gC8Q0wcWHd';

    const {
      location,
      type,
      priority,
      category,
      nte,
      area,
      description,
      attachments,
    } = values;

    const data = { 
      ticket: {
        id: ticketId,
        company: { id: companyId },
        location: { id: location },
        client: { id: user.company.id }
      },
      details: {
        area: area,
        category: category, 
        description: description,
        nte: nte,
        priority: parseInt(priority, 10),
        type: type,
      }
    }

    await addDocument(data);
    console.log(user)
    const numberOfAttachments = attachments.length
    for (let ind = 0; ind < numberOfAttachments; ind++) {
      await uploadTicketAttachments(user.company.id, location, ticketId, attachments[ind], user.id)
    }
    actions.setSubmitting(false);

    setActiveStep(activeStep + 1);
  }

  function _handleSubmit(values, actions) {
    if (isLastStep) {
      _submitForm(values, actions);
    } else {
      setActiveStep(activeStep + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  }

  function _handleBack() {
    setActiveStep(activeStep - 1);
  }

  return <Paper elevation={2} className="new-ticket">
    <Typography component="h1" variant="h4" align="center">
        Create New Ticket
    </Typography>
    <Stepper activeStep={activeStep} sx={{ padding: [3, 0, 5] }}>
      {steps.map(label => (
        <Step key={label}>
          <StepLabel>{label}</StepLabel>
        </Step>
      ))}
    </Stepper>
    <>
      {activeStep === steps.length ? (
        <Navigate to='/' replace/>
      ) : (
        <Formik
          initialValues={formInitialValues}
          validationSchema={currentValidationSchema}
          onSubmit={_handleSubmit}
        >
          {({ isSubmitting, setFieldValue }) => (
            <Form id={formId}>
              {renderStepContent(activeStep, setFieldValue)}

              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                {activeStep !== 0 && (
                  <Button onClick={_handleBack} sx={{ marginTop: 3, marginLeft: 1 }}>
                    Back
                  </Button>
                )}
                <div style={{margin: 1, position: 'relative'}}>
                  <Button
                    disabled={isSubmitting}
                    type="submit"
                    variant="contained"
                    color="primary"
                    sx={{ marginTop: 3, marginLeft: 1 }}
                  >
                    {isLastStep ? 'Create Ticket' : 'Next'}
                  </Button>
                  {isSubmitting && (
                    <CircularProgress
                      size={24}
                      sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%'
                      }}
                    />
                  )}
                </div>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </>
  </Paper>
};

export default NewTicketPage;